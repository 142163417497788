import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { manualRefresh } from "../../utils/authorizedFetch";

interface AuthWrapperProps {
  loadingText?: string;
  loginLink?: string;
  children: ReactNode;
}

const AuthWrapper: FunctionComponent<AuthWrapperProps> = ({
  children,
  loadingText = "Verifying authorization...",
  loginLink = "/login",
}) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  useEffect(() => {
    try {
      manualRefresh()
        .then(() => setIsAuthorized(true))
        .catch((error) => {
          console.error(error);
          setIsAuthorized(false);
        });
    } catch (error) {
      console.error(error);
      setIsAuthorized(false);
    }
  }, []);

  if (isAuthorized == null) {
    return (
      <section className="hero is-info is-fullheight">
        <div className="hero-body">
          <p className="title">{loadingText}</p>
        </div>
      </section>
    );
  }

  if (isAuthorized) {
    return <>{children}</>;
  }

  return <Navigate to={loginLink} />;
};

export default AuthWrapper;

import React, { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { CookieData } from "../../models/LocalizedCookieData";
import { authorizedFetch } from "../../utils/authorizedFetch";
import { handleError, handleJsonResponse } from "../../utils/handleResponse";

interface CookieCreationProps {
  refresh: () => void;
  supportedLanguages?: string[];
  cookieId?: string;
}

const CookieCreation: FunctionComponent<CookieCreationProps> = ({
  refresh,
  // With the brackets removed, syntax highlighting will break
  // prettier-ignore
  supportedLanguages = (process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",") ??
    []),
  cookieId,
}) => {
  const [cookieState, setCookieState] = useState<
    Omit<CookieData, "cookie_id"> & { cookie_id: string | undefined }
  >({
    cookie_expiration: "",
    cookie_name: "",
    host: "",
    language: supportedLanguages[0],
    name: "",
    purpose: "",
    title: "",
    privacy_url: "",
    provider: "",
    cookie_id: cookieId,
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!supportedLanguages.includes(cookieState.language)) {
      setCookieState({
        cookie_expiration: "",
        cookie_name: "",
        host: "",
        language: supportedLanguages[0],
        name: "",
        purpose: "",
        title: "",
        privacy_url: "",
        provider: "",
        cookie_id: cookieId,
      });
    }
  }, [supportedLanguages]);

  const updateCookieState =
    (prop: string) => (changeEvent: React.ChangeEvent) => {
      setCookieState({
        ...cookieState,
        // @ts-ignore
        [prop]: changeEvent.target.value,
      });
    };

  if (supportedLanguages.length === 0) return null;

  return (
    <div className="box columns is-multiline">
      {cookieId == null ? (
        <>
          <h1 className="column is-full title">Create New Cookie</h1>
          <div className="column columns is-multiline">
            <span className="column is-full">Title</span>
            <input
              className="input column is-full"
              value={cookieState?.title}
              onChange={updateCookieState("title")}
            />
          </div>
          <div className="column columns is-multiline">
            <span className="column is-full">Name</span>
            <input
              className="input column is-full"
              value={cookieState?.name}
              onChange={updateCookieState("name")}
            />
          </div>
          <div className="column columns is-multiline">
            <span className="column is-full">Host</span>
            <input
              className="input column is-full"
              value={cookieState?.host}
              onChange={updateCookieState("host")}
            />
          </div>
          <div className="column columns is-multiline">
            <span className="column is-full">Cookie Name</span>
            <input
              className="input column is-full"
              value={cookieState?.cookie_name}
              onChange={updateCookieState("cookie_name")}
            />
          </div>
        </>
      ) : null}
      <div className="column columns is-multiline">
        <span className="column is-full">Provider</span>
        <input
          className="input column is-full"
          value={cookieState?.provider}
          onChange={updateCookieState("provider")}
        />
      </div>
      <div className="column is-full columns is-multiline">
        <span className="column is-full">Purpose</span>
        <textarea
          className="textarea column is-full"
          value={cookieState?.purpose}
          onChange={updateCookieState("purpose")}
        />
      </div>
      <div className="column columns is-multiline">
        <span className="column is-full">Privacy URL</span>
        <input
          className="input column is-full"
          value={cookieState?.privacy_url}
          onChange={updateCookieState("privacy_url")}
        />
      </div>
      <div className="column is-one-quarter is-gap-1 columns is-multiline">
        <span className="column is-full">Cookie Expiration</span>
        <input
          className="input column is-full"
          value={cookieState?.cookie_expiration}
          onChange={updateCookieState("cookie_expiration")}
        />
      </div>
      <div className="column is-offset-4 is-8 is-centered">
        <div className="column columns is-multiline">
          <span className="column is-full">Language</span>
          <div className="select">
            <select
              value={cookieState.language}
              onChange={updateCookieState("language")}
            >
              {supportedLanguages.map((language) => (
                <option value={language} key={language}>
                  {language}
                </option>
              ))}
            </select>
          </div>
          <button
            className="button is-third"
            onClick={() => {
              authorizedFetch(
                cookieId == null
                  ? `//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/cookie`
                  : `//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/cookie/${cookieId}`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ...cookieState,
                  }),
                }
              )
                .then(handleJsonResponse)
                .then(() =>
                  setErrorMessage(
                    cookieId == null
                      ? "The cookie was created successfully."
                      : "The localization was added successfully."
                  )
                )
                .catch((error) => {
                  error = handleError(error);
                  switch (error.status) {
                    case 400: {
                      setErrorMessage(
                        "Some of the fields were empty or contained values that were not permitted. Verify that the title is at least 1 character long and does not yet exist."
                      );
                      break;
                    }
                    case 401: {
                      setErrorMessage(
                        "Your authorization is insufficient. To create, update and delete data, you need at least admin privileges."
                      );
                      break;
                    }
                    default:
                      setErrorMessage(error.error);
                  }
                })
                .finally(() => {
                  setCookieState({
                    cookie_expiration: "",
                    cookie_name: "",
                    host: "",
                    language: supportedLanguages[0],
                    name: "",
                    purpose: "",
                    title: "",
                    privacy_url: "",
                    provider: "",
                    cookie_id: cookieId,
                  });
                  refresh();
                });
            }}
          >
            {cookieId == null ? "Create Cookie" : "Add Localization"}
          </button>
        </div>
      </div>
      {errorMessage ? (
        <div className="column columns is-centered">
          <span className="is-warning notification">
            <button className="delete" onClick={() => setErrorMessage("")} />
            {errorMessage}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CookieCreation;

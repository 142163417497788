import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CookieConfiguration from "../../components/CookieConfiguration/CookieConfiguration";
import CookieCreation from "../../components/CookieCreation/CookieCreation";
import { LocalizedCookieGroups } from "../../models/LocalizedCookieData";
import { authorizedFetch } from "../../utils/authorizedFetch";
import { handleError, handleJsonResponse } from "../../utils/handleResponse";

export default function Dashboard() {
  const [cookieGroups, setCookieGroups] = useState<LocalizedCookieGroups>({});
  const navigate = useNavigate();

  const refresh = () =>
    authorizedFetch(
      `//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/cookie`
    )
      .then(handleJsonResponse)
      .then((cookieResponse) => {
        setCookieGroups(cookieResponse);
      })
      .catch((error) => {
        handleError(error);
        navigate("/login");
      });

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div>
      <div className="section">
        <h1 className="title is-1">Dashboard</h1>
        {Object.keys(cookieGroups).map((cookieId) => (
          <CookieConfiguration
            key={cookieId}
            refresh={refresh}
            cookieProps={cookieGroups[cookieId]}
          />
        ))}
        <CookieCreation refresh={refresh} />
      </div>
    </div>
  );
}

export function handleJsonResponse(res: Response) {
  if (!res?.ok) {
    return Promise.reject({ error: "Response not ok", status: res.status });
  }

  const contentType = res.headers.get("Content-Type");

  if (
    !contentType?.startsWith("application/json") ||
    typeof res.json !== "function"
  ) {
    return Promise.reject({
      error: "Response has no JSON body",
      status: res.status,
    });
  }

  return res.json();
}

export function handleBlobResponse(res: Response) {
  if (!res?.ok) {
    return Promise.reject({ error: "Response not ok", status: res.status });
  }

  return res.blob();
}

export function handleError(error: { error: string; status: number }) {
  console.error(error.error);
  return error;
}

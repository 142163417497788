import React, { FunctionComponent, ReactNode, useState } from "react";
import Expand from "../../imgs/expand_more_black_24dp.svg";
import Collapse from "../../imgs/expand_less_black_24dp.svg";

interface AccordionProps {
  title: string;
  children: ReactNode | ReactNode[];
}

const Accordion: FunctionComponent<AccordionProps> = ({ title, children }) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div
      className="box columns is-multiline"
      onClick={() => {
        if (showContent) return;

        setShowContent(true);
      }}
    >
      <span
        className="title column is-full"
        onClick={() => {
          if (!showContent) return;

          setShowContent(false);
        }}
      >
        {title}
        <img
          className="icon"
          src={showContent ? Expand : Collapse}
          alt={showContent ? "Expand" : "Collapse"}
        />
      </span>
      <span>{showContent ? children : undefined}</span>
    </div>
  );
};

export default Accordion;

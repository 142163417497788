export function convertDateToEpochTimestamp(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

export function convertEpochTimestampToDate(timestamp: number) {
  return new Date(timestamp * 1000);
}

export function epochNow() {
  return Math.floor(new Date().getTime() / 1000);
}

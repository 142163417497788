import React, { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { LocalizedCookie } from "../../models/LocalizedCookieData";
import Accordion from "../Accordion/Accordion";
import CookieCreation from "../CookieCreation/CookieCreation";
import LocalizedCookieConfiguration from "./LocalizedCookieConfiguration";

interface CookieConfigurationProps {
  refresh: () => void;
  cookieProps: LocalizedCookie;
}

const CookieConfiguration: FunctionComponent<CookieConfigurationProps> = ({
  refresh,
  cookieProps,
}) => {
  // default is first available language
  const [cookieState, setCookieState] = useState(cookieProps);
  const title = Object.values(cookieProps)[0].title;
  const cookieId = Object.values(cookieProps)[0].cookie_id;

  useEffect(() => {
    setCookieState(cookieProps);
  }, [cookieProps]);

  return (
    <Accordion title={title}>
      {Object.keys(cookieProps).map((language) => {
        const updateCookieState =
          (prop: string) =>
          (
            changeEvent: React.ChangeEvent<
              HTMLInputElement | HTMLTextAreaElement
            >
          ) => {
            setCookieState({
              ...cookieState,

              [language]: {
                ...cookieState[language],
                [prop]: changeEvent.target.value,
              },
            });
          };

        return (
          <LocalizedCookieConfiguration
            key={cookieId + language}
            localizedCookie={cookieState[language]}
            refresh={refresh}
            updateCookieState={updateCookieState}
          />
        );
      })}
      <CookieCreation
        refresh={refresh}
        cookieId={cookieId}
        supportedLanguages={(
          process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",") ?? []
        ).filter((language) => !Object.keys(cookieProps).includes(language))}
      />
    </Accordion>
  );
};

export default CookieConfiguration;

import React, { useState } from "react";
import "./Login.css";
import { handleError, handleJsonResponse } from "../../utils/handleResponse";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  async function login() {
    fetch(
      `//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/token/authenticate`,
      {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleJsonResponse)
      .then((data) => {
        if (data == null) {
          setErrorMessage("The backend didn't respond adequately");
          return;
        }
        localStorage.setItem("JWT", data.token);
        localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
        navigate("/", { replace: true });
      })
      .catch((error) => {
        handleError({ error, status: 500 });
        setErrorMessage(error.error ?? "The authentication failed");
      });
  }

  return (
    <form
      onSubmit={(submitEvent) => {
        submitEvent.preventDefault();
        login();
      }}
      className="section"
    >
      <div className="columns is-centered">
        <input
          type="text"
          placeholder="E-mail"
          className="column is-3"
          value={email}
          onChange={(changeEvent) => setEmail(changeEvent.target.value)}
        />
      </div>

      <div className="columns is-centered">
        <input
          type="password"
          placeholder="Password"
          className="column is-3"
          value={password}
          onChange={(changeEvent) => setPassword(changeEvent.target.value)}
        />
      </div>
      <div className="columns is-centered">
        <input type="submit" className="column is-3" value="Anmelden" />
      </div>
      {errorMessage ? (
        <div className="columns is-centered">
          <span className="is-warning notification">
            <button className="delete" onClick={() => setErrorMessage("")} />
            {errorMessage}
          </span>
        </div>
      ) : null}
    </form>
  );
}

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Fallback from "./pages/Fallback/Fallback";
import Login from "./pages/Login/Login";
import AuthWrapper from "./components/AuthWrapper/AuthWrapper";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AuthWrapper>
              <Dashboard />
            </AuthWrapper>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Fallback />} />
      </Routes>
    </Router>
  );
}

export default App;

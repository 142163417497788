import React, { FunctionComponent, useState } from "react";
import { CookieData } from "../../models/LocalizedCookieData";
import { authorizedFetch } from "../../utils/authorizedFetch";
import { handleError, handleJsonResponse } from "../../utils/handleResponse";

interface LocalizedCookieConfigurationProps {
  refresh: () => void;
  updateCookieState: (
    prop: string
  ) => (
    changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  localizedCookie: CookieData;
}

const LocalizedCookieConfiguration: FunctionComponent<
  LocalizedCookieConfigurationProps
> = ({
  refresh,
  updateCookieState,
  localizedCookie,
}: LocalizedCookieConfigurationProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  if (localizedCookie == null) return null;
  return (
    <div
      className={"localized-cookie-configuration is-full columns is-multiline"}
    >
      <div className="column columns is-multiline">
        <span className="column is-full">Name</span>
        <input
          className="input column is-full"
          value={localizedCookie?.name}
          onChange={updateCookieState("name")}
        />
      </div>
      <div className="column columns is-multiline">
        <span className="column is-full">Provider</span>
        <input
          className="input column is-full"
          value={localizedCookie?.provider}
          onChange={updateCookieState("provider")}
        />
      </div>
      <div className="column is-full columns is-multiline">
        <span className="column is-full">Purpose</span>
        <textarea
          className="textarea column is-full"
          value={localizedCookie?.purpose}
          onChange={updateCookieState("purpose")}
        />
      </div>
      <div className="column columns is-multiline">
        <span className="column is-full">Privacy URL</span>
        <input
          className="input column is-full"
          value={localizedCookie?.privacy_url}
          onChange={updateCookieState("privacy_url")}
        />
      </div>
      <div className="column columns is-multiline">
        <span className="column is-full">Host</span>
        <input
          className="input column is-full"
          value={localizedCookie?.host}
          onChange={updateCookieState("host")}
        />
      </div>
      <div className="column columns is-multiline">
        <span className="column is-full">Cookie Name</span>
        <input
          className="input column is-full"
          value={localizedCookie?.cookie_name}
          onChange={updateCookieState("cookie_name")}
        />
      </div>
      <div className="column is-one-quarter is-gap-1 columns is-multiline">
        <span className="column is-full">Cookie Expiration</span>
        <input
          className="input column is-full"
          value={localizedCookie?.cookie_expiration}
          onChange={updateCookieState("cookie_expiration")}
        />
      </div>
      <div className="column is-offset-8 is-4">
        <button
          className="button is-third"
          onClick={() => {
            authorizedFetch(
              `//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/cookie/${localizedCookie?.cookie_id}/${localizedCookie?.language}`,
              {
                method: "DELETE",
              }
            )
              .then(handleJsonResponse)
              .then(() =>
                setErrorMessage("Cookie has been deleted successfully.")
              )
              .catch((error) => {
                error = handleError(error);
                // switch case is overkill here, but it keeps it easily extensible
                switch (error.status) {
                  case 401: {
                    setErrorMessage(
                      "Your authorization is insufficient. To create, update and delete data, you need at least admin privileges."
                    );
                    break;
                  }
                  default:
                    setErrorMessage(error.error);
                }
              })
              .finally(refresh);
          }}
        >
          Delete
        </button>
        <span className="has-text-justified has-text-centered column">
          {localizedCookie?.language}
        </span>
        <button
          className="button is-third"
          onClick={() => {
            if (
              Object.values(localizedCookie).some(
                (value) => value?.length === 0
              )
            ) {
              setErrorMessage("The values should not be empty.");
              return;
            }
            authorizedFetch(
              `//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/cookie/${localizedCookie?.cookie_id}/${localizedCookie?.language}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(localizedCookie),
              }
            )
              .then(handleJsonResponse)
              .then(() =>
                setErrorMessage(
                  "The localization has been updated successfully."
                )
              )
              .catch((error) => {
                error = handleError(error);
                switch (error.status) {
                  case 400: {
                    setErrorMessage(
                      "Some of the fields were empty or contained values that were not permitted."
                    );
                    break;
                  }
                  case 401: {
                    setErrorMessage(
                      "Your authorization is insufficient. To create, update and delete data, you need at least admin privileges."
                    );
                    break;
                  }
                  default:
                    setErrorMessage(error.error);
                }
              })
              .finally(refresh);
          }}
        >
          Save Changes
        </button>
      </div>
      {errorMessage ? (
        <div className="column columns is-centered">
          <span className="is-warning notification">
            <button className="delete" onClick={() => setErrorMessage("")} />
            {errorMessage}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default LocalizedCookieConfiguration;

import * as jwt from "jose";
import { epochNow } from "./epoch";
import { handleError } from "./handleResponse";

export const manualRefresh = async () => {
  const refreshToken = JSON.parse(
    localStorage.getItem("refreshToken") ?? "null"
  );

  if (refreshToken == null) {
    throw new Error("No refresh token could be found");
  }

  console.log("Refreshing token");
  // use refresh token to get a new one if it has
  const newTokenData = await fetch(`//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/token/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refreshToken: refreshToken.id,
    }),
  })
    .then((response) => {
      // if the refresh request has been rejected, the refresh token must be faulty,
      // so the user is not authenticated. Throw an error, return null and reject.
      if (!response.ok) {
        throw new Error("The refresh token has expired or was fake");
      }

      return response.json();
    })
    .catch(handleError);

  if (newTokenData == null) {
    localStorage.removeItem("JWT");
    localStorage.removeItem("refreshToken");
    throw new Error("The refresh token has expired or was fake");
  }

  localStorage.setItem("JWT", newTokenData.token!);
  localStorage.setItem(
    "refreshToken",
    JSON.stringify(newTokenData.refreshToken)
  );
};

export const authorizedFetch = async (
  info: RequestInfo,
  init?: RequestInit | undefined
) => {
  // Try using auth data from storage
  let authToken = localStorage.getItem("JWT");
  const refreshToken = JSON.parse(
    localStorage.getItem("refreshToken") ?? "null"
  );

  // if not such data exists, the user hasn't been authenticated yet
  // or has messed with the auth data
  if (authToken == null || refreshToken == null) {
    throw new Error("No JWT or refresh token could be found");
  }

  const claims = jwt.decodeJwt(authToken);

  if (typeof claims === "string" || claims == null) {
    throw new Error("The JWT claims are invalid or the token is malformed");
  }

  // Check if token has expired
  if (claims.exp! <= epochNow()) {
    console.log("Refreshing token");
    // use refresh token to get a new one if it has
    const newTokenData = await fetch(`//${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api/token/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: refreshToken.id,
      }),
    })
      .then((response) => {
        // if the refresh request has been rejected, the refresh token must be faulty,
        // so the user is not authenticated. Throw an error, return null and reject.
        if (!response.ok) {
          throw new Error("The refresh token has expired or was fake");
        }

        return response.json();
      })
      .catch(handleError);

    if (newTokenData == null) {
      localStorage.removeItem("JWT");
      localStorage.removeItem("refreshToken");
      throw new Error("The refresh token has expired or was fake");
    }

    // Use new jwt for authentication
    authToken = newTokenData.token;
    localStorage.setItem("JWT", authToken!);
    localStorage.setItem(
      "refreshToken",
      JSON.stringify(newTokenData.refreshToken)
    );
  }

  // send request with valid token, unexpired
  // if it's rejected, the user has insufficient rights

  return fetch(info, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${authToken}`,
    },
  }).then((response) => {
    if (response.status === 401) {
      throw new Error(
        "The authorization token is invalid or it provides insufficient rights"
      );
    }

    return response;
  });
};
